import logo from "./logo.svg";
import logotype from "./logotype.svg";
import "./App.css";
import React, { useState } from "react";

const sheetUrl = process.env.REACT_APP_SHEET_URL;

const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

const validateEmail = (email) => {
  if (email && email.match(isValidEmail)) {
    return true;
  } else {
    return false;
  }
};

const App = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);

  const handleChange = (e) => {
    if (submitted) {
      setSubmitted(false);
    }

    setShowValidationError(false);
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (validateEmail(email) === false) {
      setShowValidationError(true);
      setLoading(false);
    } else {
      const data = new FormData();
      data.append("email", email);

      try {
        await fetch(sheetUrl, {
          method: "POST",
          body: data,
          muteHttpExceptions: true,
        });

        setLoading(false);
        setEmail("");
        setSubmitted(true);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="container">
      <div className="content">
        <div className="logoContainer">
          <img src={logotype} className="logotype" alt="logotype" />
          <img src={logo} className="logo" alt="logo" />
        </div>

        <div className="description">
          Join the newsletter to keep up to date with O-Core & it's launch.
        </div>

        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="inputContainer">
              <input
                type="text"
                placeholder="Email"
                className="input"
                id="email"
                value={email}
                onChange={handleChange}
              />
              <button type="submit">
                {submitted ? (
                  "Thanks!"
                ) : loading ? (
                  <div className="loader"></div>
                ) : (
                  "Join"
                )}
              </button>
            </div>
          </form>
          {showValidationError && <div className="error">Oops, seems like there's an error in the email format. Please verify and retry</div>}
        </div>
      </div>

      <div className="footer"></div>
    </div>
  );
};

export default App;
